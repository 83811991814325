import React from 'react'
import { Link } from 'gatsby'

const LinkCard = ({ title, blurb, slug }) => {
  return (
    <Link to={`/${slug}`} className="link-card">
      <div className="bg-faded p-5 rounded">
        <div className="section-heading" >
          <span className="section-heading-upper">{title}</span>
        </div>
        <p className="mb-0">{blurb}</p>
      </div>
    </Link>
  )
}

export default LinkCard
