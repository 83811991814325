import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Layout from '../components/layout'
import { useStaticQuery, graphql } from 'gatsby'
import LinkCard from '../components/linkCard'
import Col from 'react-bootstrap/Col'




const Ministries = () => {
  const data = useStaticQuery(ministriesQuery)

  return (
    <>
      <Layout title={'Ministries'} subtitle={'HRBC'}>
        <Container style={{marginTop: '5rem', marginBottom: '5rem'}}>
          <Row style={{marginTop: '15px', marginBottom: '15px'}}>
            {data.allStrapiMinistry.nodes.map((ministry, index) =>
              <Col key={index} style={{margin: '15px'}}>
                <LinkCard title={ministry.name} slug={'ministries/'+ministry.slug} blurb="Click to find out more" />
              </Col>
            )}
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Ministries



const ministriesQuery = graphql`{
  allStrapiMinistry {
    nodes {
      name
      strapiId
      updated_at
      slug
    }
  }
}
`